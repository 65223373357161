import React from 'react';
import Layout from '../component/layout';
import FooterComponent from '../component/footerComponent';
import Swiper from '../component/swiper';
import { Link } from 'gatsby';
import image from '../images/thank-you.png';

const thankYou = () => {
  return (
    <Layout
      seo={{
        title: '',
        metaDesc: '',
      }}
      title={'Thank you'}
    >
      <div
        style={{
          background: `radial-gradient(50% 50% at 50% 50%, rgba(212, 230, 252, 0.5) 0%, rgba(255, 255, 255, 0.28) 100%),url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '70% 50%',
          backdropFilter: 'blur(117px)',
          padding: '253px 0',
        }}
      >
        <div className='full-container'>
          <div style={{ padding: '0 40px' }}>
            <p
              style={{
                fontWeight: '800',
                fontSize: '56px',
                lineHeight: '67px',
                color: '#243062',
              }}
            >
              Thank You
            </p>
            <p
              style={{
                fontWeight: '700',
                fontSize: '40px',
                lineHeight: '55px',
                color: '#000',
                maxWidth: '691px',
                margin: '40px 0',
              }}
            >
              You Are One Step Closer To Solving Your Development Needs
            </p>
            <p
              style={{
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '25px',
                color: '#000',
                maxWidth: '575px',
                marginBottom: '40px',
              }}
            >
              We will review your information and get in touch soon. In the
              meantime, discover how we helped companies like yours reach the
              next stage of business growth
            </p>
            <Link to='/' className='custom-dev-drive__link'>
              Go Back To Home
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          background: 'linear-gradient(108.34deg, #09062D 0%, #36498C 100%)',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            paddingTop: '60px',
            paddingBottom: '100px',
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: ' 40px',
              lineHeight: '55px',
              color: 'white',
            }}
          >
            You Envision It. We Create It
          </p>
          <p
            style={{
              fontWeight: 400,
              fontSize: ' 20px',
              lineHeight: '45px',
              color: 'white',
            }}
          >
            See what our happy clients say about our services.
          </p>
        </div>
        <Swiper color={'#fff'} />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default thankYou;
